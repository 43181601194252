<template>
    <div class="ucenter">
        <van-nav-bar
                :border="false"
                :placeholder="true"
                :fixed="true"
                :title="$t('我的团队')"
                left-arrow
                @click-left="onClickLeft"
        >
        </van-nav-bar>
        <div class="inc-tabs">
            <van-tabs
                    v-model="active"
                    @click="onClick"
                    v-if="agent_list.length > 0"
                >
                <van-tab
                        v-for="(item, index) in agent_list"
                        :key="`agent_tab${index}`"
                        :title="$t(item.name)"
                        :name="item.id"
                >
                    <template #title>
                        <div class="tm-tab-title">
                            {{$t(item.name)}}
                        </div>
                    </template>
                </van-tab>
            </van-tabs>
        </div>
        <div class="wrapper">
            <van-pull-refresh
                    v-model="refreshing"
                    @refresh="onRefresh"
                    :pulling-text="$t('下拉即可刷新')"
                    :loosing-text="$t('释放即可刷新')"
                    :loading-text="$t('加载中')"
            >
                <van-empty v-show="isEmpty" :description="$t('没有记录')"/>
                <van-list
                        v-show="!isEmpty"
                        v-model="loading"
                        :finished="finished"
                        :loading-text="$t('加载中')"
                        :finished-text="$t('没有更多了')"
                        @load="getData"
                >
                    <div class="agent-list">
                        <div class="bs-panel agent-panel" v-for="(item, index) in data" :key="index">
                            <div class="rew-cell_row">
                                <div class="rew-title">{{ item.username }}</div>
                                <div v-if="active == 1" class="rew-title text-blue">{{ item.country + item.phone }}</div>
                            </div>
                            <div class="rew-cell_foot">
                                <div class="text-gray" >
                                    {{ item.created_at }}
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                active: 1,
                show: false,
                agent_list: [],
                data: [],
                loading: false,
                finished: false,
                refreshing: false,
                isEmpty: false,
                page: 0,
                size: 20,
            };
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1);
            },
            onClick(name, title) {
                this.onRefresh();
            },
            getData() {
                let url = this.$api_url.get_team_user + "?page=" + this.page + "&level=" + this.active;
                this.$axios
                    .get(url)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.page += 1;
                            if (res.data.agent_list.length > 0) {
                                this.agent_list = res.data.agent_list;
                            }
                            if (this.data.length == 0) {
                                this.data = res.data.data;
                        console.log(res.data)

                            } else {
                                this.data = this.data.concat(res.data.data);
                            }
                            if (res.data.data.length <= 0) {
                                this.finished = true;
                            }
                        } else {
                            this.finished = true;
                        }
                    })
                    .catch((err) => {
                        this.finished = true;
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        this.loading = false;
                        this.refreshing = false;
                        this.isEmpty = this.data.length === 0;
                    });
            },
            onRefresh() {
                this.data = [];
                this.page = 0;
                // 清空列表数据
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.getData();
            },
        },
        mounted() {
        },
    };
</script>